import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import './Home.scss';
import { ArticleGraphView, ArticleReadView, CustomLocationState, OrganisationSummaryView } from '../../types/types';
import ArticleMap from '../map/ArticleMap';
import { useAppShellContext } from '../shell/AppShellContext';
import HeaderBackButton from '../common/HeaderBackButton';
import { getArticleById, getArticleGraphById } from '../../services/Article';
import { useGlobalState } from '../../context/GlobalState';
import OrganisationMap from '../map/OrganisationMap';
import { AxiosError } from 'axios';
import { getOrganisationsSummary } from '../../services/Organisations';
import { getClassifiers } from '../../services/Classifier';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { selectContent } from '../../services/Analytics';
import { getAlternateId } from '../../services/AlternateID';
import { getUrl } from '../../services/URLs';
import { useHomeContext } from '../../context/HomeContext';
import { getGroups } from '../../services/Groups';

const HomeScreen = () => {
  const params = useParams<{ orgName: string }>();
  const appShellContext = useAppShellContext();
  const globalState = useGlobalState();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [graph, setGraph] = useState<ArticleGraphView>();
  const [organisationSummaries, setOrganisationSummaries] = useState<OrganisationSummaryView[]>();
  const [showWarning, setShowWarning] = useState<boolean>();

  const { classifiers, setClassifiers, loading, setLoading, setGroups } = useHomeContext();

  const fetchData = async (epc: string) => {
    setLoading(true);
    try {
      let _article: ArticleReadView | null = null;
      const isAlternateId = epc.split('-')[0] === 'alternate';
      if (isAlternateId) {
        const _alternateId = await getAlternateId(epc);
        if (_alternateId.entityType === 'Article') {
          _article = await getArticleById(_alternateId.entityId);
        } else if (_alternateId.entityType === 'Custom URL') {
          const url = await getUrl(_alternateId.entityId);
          navigate(url.url);
          return;
        }
        selectContent({ content_type: 'article_alternate_id', content_id: epc });
      } else {
        if (isNaN(Number(epc))) {
          toast.error('Invalid EPC number!');
          return;
        }
        _article = await getArticleById(Number(epc));
        selectContent({ content_type: 'article_epc', content_id: epc });
      }
      if (!_article) {
        toast.error('No article found!');
        return;
      }
      if (_article.disabled) {
        setShowWarning(true);
      }
      if (_article.customIcon || _article.customUiColor) {
        globalState.setTheme({ primaryColor: _article.customUiColor, logo: _article.customIcon });
      }
      if (_article.urlAlias) {
        navigate(_article.urlAlias);
        return;
      }
      const _graph = await getArticleGraphById(_article.id);
      setGraph(_graph);
    } catch (e) {
      toast.error('Fel QR-kod!', { autoClose: 3000 });
      setGraph(undefined);
    } finally {
      setLoading(false);
    }
  };

  const fetchOrganisationSummaries = async () => {
    const data = await getOrganisationsSummary();
    setOrganisationSummaries(data.records);
    const _categories = await getClassifiers();
    setClassifiers(_categories.records);
    const _groups = await getGroups();
    setGroups(_groups.records);
  };

  useEffect(() => {
    const epc = searchParams.get('epc');
    if (epc) {
      fetchData(epc);
    } else {
      globalState.resetTheme();
      setGraph(undefined);
      if (classifiers.length === 0) {
        try {
          setLoading(true);
          fetchOrganisationSummaries().finally(() => setLoading(false));
        } catch (e) {
          globalState.handleResponseError(e as AxiosError);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    const state = location.state as CustomLocationState;
    if (state && state.prevPage) {
      appShellContext.setHeaderLeft(
        <HeaderBackButton onClick={() => navigate(location.state.prevPage, { replace: true })} />
      );
    } else {
      appShellContext.setHeaderLeft(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  return graph ? (
    <>
      <div className='warning-container'>
        <Dialog open={showWarning ?? false} onClose={() => setShowWarning(false)}>
          <DialogTitle className='warning-dialog-title'>{'Viktig Information!'}</DialogTitle>
          <DialogContent className='warning-dialog-content' style={{ marginTop: '10px' }}>
            <DialogContentText>{'Innehållet i skannad förpackningar har återkallats av leverantör.'}</DialogContentText>
          </DialogContent>
          <DialogActions className='warning-dialog-content'>
            <Button onClick={() => setShowWarning(false)} variant='contained' color='error'>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <ArticleMap loading={loading} data={graph} />
    </>
  ) : (
    <>
      <OrganisationMap data={organisationSummaries} initialSearch={params.orgName} />
    </>
  );
};
export default HomeScreen;
