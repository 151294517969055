import { GoogleMap, InfoBox, Marker, useJsApiLoader } from '@react-google-maps/api';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { mapStyle } from './MapStyle';
import './Map.scss';
import Box from '@mui/material/Box';
import { IconButton, Typography } from '@mui/material';
import { OrganisationSummaryView } from '../../types/types';

import markerPerson from '../../utils/img/markerPerson.png';
import markerPersonWhite from '../../utils/img/markerPersonWhite.png';
import { isMobileDevice, prepareOrgNameForLink, searchInOrganisationSummaries } from '../../utils/HelperFunctions';
import CloseIcon from '@mui/icons-material/HighlightOff';
import { grey } from '@mui/material/colors';
import ComponentLoader, { componentLoaderStyles } from '../common/componentLoader/ComponentLoader';
import { ENV_VARIABLES } from '../../config';
import HomeScreenOverlay from './HomeScreenOverlay';
import { selectContent } from '../../services/Analytics';
import { useHomeContext } from '../../context/HomeContext';
import LoaderImg from '../../utils/img/AppTrac.svg';
import OrganisationInfoBox from '../common/OrganisationInfoBox';
import { useLocation } from 'react-router-dom';

type Props = {
  data?: OrganisationSummaryView[];
  disableOverLay?: boolean;
  initialSearch?: string;
};

type Libraries = ('drawing' | 'geometry' | 'localContext' | 'places' | 'visualization')[];
const libraries: Libraries = ['drawing'];
const OrganisationMap = (props: Props) => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [satellite, setSatellite] = useState<boolean>(false);
  const [userPosition, setUserPosition] = useState<GeolocationCoordinates>();
  const [isStreetView, setIsStreetView] = useState<boolean>(false);
  const [streetView, setStreetView] = useState<boolean>(false);
  const [filteredOrganisations, setFilteredOrganisations] = useState<OrganisationSummaryView[]>([]);
  const [mapCenter, setMapCenter] = useState<google.maps.LatLngLiteral>({ lat: 59.2781126, lng: 15.1674353 });
  const [visibleMarkerCount, setVisibleMarkerCount] = useState<number>(Number.MAX_SAFE_INTEGER);
  const [mapBounds, setMapBounds] = useState<google.maps.LatLngBounds | undefined>();
  const [showingUserTooltip, setShowingUserTooltip] = useState<boolean>(false);

  const homeCtx = useHomeContext();
  const location = useLocation();

  const randomZIndexListRef = useRef<number[]>([]);

  const ICON_SIZE = 17;
  const MARKER_THRESHOLD = 50;

  useEffect(() => {
    const array: number[] = Array.from({ length: props.data?.length ?? 0 }, (_, index) => index);
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    randomZIndexListRef.current = array;
  }, [props.data]);

  useEffect(() => {
    const bounds = map?.getBounds();
    if (bounds !== undefined && filteredOrganisations !== undefined) {
      setVisibleMarkerCount(filteredOrganisations.filter((org) => bounds.contains(org)).length);
    }
    if (homeCtx.selectedOrganisation && !filteredOrganisations.includes(homeCtx.selectedOrganisation)) {
      homeCtx.setSelectedOrganisation(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapBounds, filteredOrganisations]);

  useEffect(() => {
    const drawCircle = (
      context: CanvasRenderingContext2D,
      color: string | CanvasGradient,
      x: number,
      y: number,
      radius: number,
      fill: boolean = true
    ) => {
      context.beginPath();
      context.arc(x, y, radius, 0, 2 * Math.PI);
      if (fill) {
        context.fillStyle = color;
        context.fill();
      } else {
        context.strokeStyle = color;
        context.stroke();
      }
      context.closePath();
    };

    const icons = new Map<number, string>();
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = ICON_SIZE;
    canvas.height = ICON_SIZE;
    const radius = ICON_SIZE / 2;
    homeCtx.classifiers.forEach((cat) => {
      if (context) {
        drawCircle(context, cat.color, radius, radius, radius * 0.5);
        drawCircle(context, 'white', radius, radius, radius * 0.5, false);
        icons.set(cat.id, canvas.toDataURL());
        context.clearRect(0, 0, ICON_SIZE, ICON_SIZE);
      }
    });
    homeCtx.setSimpleIcons(icons);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homeCtx.classifiers]);

  useEffect(() => {
    const id = navigator.geolocation.watchPosition(
      (position) => {
        setUserPosition(position.coords);
      },
      (error) => {
        console.log('Position watch error:', error);
      },
      { enableHighAccuracy: false, timeout: 5000, maximumAge: 0 }
    );
    return () => {
      navigator.geolocation.clearWatch(id);
    };
  }, []);

  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: ENV_VARIABLES.googleMapsApiKey!,
    libraries: libraries
  });

  const onLoad = useCallback(
    (map: google.maps.Map) => {
      //Set the bounds to the furthest north and south points on the map in order to avoid zooming out or panning further than those bounds
      const maxBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(-85, -180),
        new google.maps.LatLng(85, 180)
      );
      const params = new URLSearchParams(location.search);

      const zoomString = params.get('zoom');
      var zoom;
      if (zoomString !== null) {
        zoom = parseInt(zoomString);
      }

      map.setOptions({
        zoom: Number.isNaN(zoom) ? 7 : zoom ?? 7,
        controlSize: 30,
        restriction: {
          latLngBounds: maxBounds,
          strictBounds: true
        },
        fullscreenControl: false,
        mapTypeControl: false,
        styles: mapStyle
      });
      map.setTilt(45);

      map.addListener('bounds_changed', () => {
        setMapBounds(map.getBounds());
      });

      map.getStreetView().addListener('visible_changed', () => {
        setStreetView(map.getStreetView().getVisible());
        console.log(`street viewed? ${streetView}`);
      });

      map.getStreetView().addListener('visible_changed', () => {
        setStreetView(map.getStreetView().getVisible());
        console.log(`street viewed? ${streetView}`);
      });

      setMap(map);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [streetView]
  );

  const onUnmount = useCallback((map: google.maps.Map) => {
    setMap(null);
  }, []);

  const getRelevantIconId = useCallback(
    (categoryIds: number[]) => {
      const filteredCategories = [
        ...homeCtx.filteredMainCategories,
        ...homeCtx.filteredSubCategories,
        ...homeCtx.filteredGroups
      ];
      if (categoryIds !== null) {
        const options = categoryIds.filter((id) => filteredCategories.includes(id));
        return filteredCategories.length > 0 ? options[0] : categoryIds[0];
      }
    },
    [homeCtx.filteredMainCategories, homeCtx.filteredSubCategories, homeCtx.filteredGroups]
  );

  const getIcon = useCallback(
    (ids: number[]) => {
      return homeCtx.classifiers.find((category) => category.id === getRelevantIconId(ids))?.icon;
    },
    [homeCtx.classifiers, getRelevantIconId]
  );

  const applyCategoryFilter = useCallback(
    (categoryIds: number[]): boolean => {
      const filteredCategories = [
        ...homeCtx.filteredMainCategories,
        ...homeCtx.filteredSubCategories,
        ...homeCtx.filteredGroups
      ];
      return filteredCategories.every(
        (filteredCategory) => categoryIds !== null && categoryIds.includes(filteredCategory)
      );
    },
    [homeCtx.filteredMainCategories, homeCtx.filteredSubCategories, homeCtx.filteredGroups]
  );

  useEffect(() => {
    const searchOrg = props.data?.find((org) => prepareOrgNameForLink(org.name) === props.initialSearch);
    if (searchOrg) {
      selectContent({ content_type: 'organisation_url', content_id: `${searchOrg.id}` });
      homeCtx.setSearchQuery(searchOrg.name);
      setMapCenter(searchOrg);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.initialSearch, props.data]);

  useEffect(() => {
    const filteredCategories = [
      ...homeCtx.filteredMainCategories,
      ...homeCtx.filteredSubCategories,
      ...homeCtx.filteredGroups
    ];
    if (homeCtx.searchQuery || filteredCategories.length > 0) {
      const filtered = searchInOrganisationSummaries(props.data ?? [], homeCtx.searchQuery ?? '').filter(
        (organisation) => applyCategoryFilter(organisation.classifierIds)
      );

      setFilteredOrganisations(filtered);
    }
  }, [
    homeCtx.searchQuery,
    homeCtx.filteredMainCategories,
    homeCtx.filteredSubCategories,
    homeCtx.filteredGroups,
    props.data,
    applyCategoryFilter
  ]);

  useEffect(() => {
    if (map) {
      map.setOptions({ mapTypeId: satellite ? google.maps.MapTypeId.HYBRID : google.maps.MapTypeId.ROADMAP });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [satellite]);

  useEffect(() => {
    let streetViewChangeListener: google.maps.MapsEventListener | null = null;
    if (map) {
      streetViewChangeListener = google.maps.event.addListener(map.getStreetView(), 'visible_changed', () => {
        setIsStreetView(map.getStreetView().getVisible());
      });
    }
    return () => {
      if (streetViewChangeListener && map) google.maps.event.removeListener(streetViewChangeListener);
    };
  }, [map]);

  useEffect(() => {
    if (map !== null && !homeCtx.loading) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (position && !props.initialSearch) {
            const currentLocation = { lat: position.coords.latitude, lng: position.coords.longitude };
            map.setCenter(currentLocation);
          }
        },
        (error) => console.warn('Browser location error', error)
      );
    }
    const filtered = searchInOrganisationSummaries(props.data ?? [], homeCtx.searchQuery ?? '').filter((organisation) =>
      applyCategoryFilter(organisation.classifierIds)
    );

    setFilteredOrganisations(filtered);
  }, [map, props.data, props.initialSearch, homeCtx.searchQuery, applyCategoryFilter, homeCtx.loading]);

  const getPinSize = () => {
    const pinSizeString = new URLSearchParams(location.search).get('pinsize');
    switch (pinSizeString) {
      case 'xs':
        return new google.maps.Size(20, 25);
      case 's':
        return new google.maps.Size(33, 40);
      default:
        return new google.maps.Size(45, 55);
    }
  };

  return loadError ? (
    <Box sx={componentLoaderStyles.fullCentered}>
      <Typography variant='h6' color={grey[100]}>
        Map Loading Error!
      </Typography>
      <Typography variant='body1' color={grey[100]}>
        Please refresh the page!
      </Typography>
    </Box>
  ) : !isLoaded || homeCtx.loading ? (
    <ComponentLoader bgColor='#02636e' bgImg={LoaderImg} />
  ) : (
    <GoogleMap
      id='react-google-maps'
      mapContainerClassName={'google-map-container-style'}
      onLoad={onLoad}
      onUnmount={onUnmount}
      center={mapCenter}
    >
      {homeCtx.selectedOrganisation && (
        <OrganisationInfoBox
          organisation={homeCtx.selectedOrganisation}
          onClose={() => homeCtx.setSelectedOrganisation(undefined)}
        />
      )}
      {userPosition && (
        <Marker
          position={{ lat: userPosition!.latitude, lng: userPosition!.longitude }}
          icon={{
            url: satellite || isStreetView ? markerPersonWhite : markerPerson,
            scaledSize: new google.maps.Size(40, 40)
          }}
          onClick={() => {
            setShowingUserTooltip((prev) => !prev);
          }}
        >
          {showingUserTooltip && (
            <InfoBox
              options={{
                enableEventPropagation: true,
                closeBoxURL: '',
                boxStyle: {
                  boxShadow: ' 8px 8px 41px -15px rgba(0,0,0,0.75)',
                  backgroundColor: 'rgba(255,255,255,0)'
                }
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'white',
                  padding: '1.5rem .5rem',
                  borderRadius: '1rem',
                  minWidth: isMobileDevice() ? 'calc(window.innerWidth - 2rem)' : '25%'
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant='body2' align='left' noWrap>
                    Du är här
                  </Typography>
                </Box>
                <Box>
                  <IconButton
                    aria-label='close button'
                    onClick={() => homeCtx.setSelectedOrganisation(undefined)}
                    sx={{ padding: 0, marginLeft: 2 }}
                  >
                    <CloseIcon sx={{ color: grey[600] }} fontSize='small' />
                  </IconButton>
                </Box>
              </Box>
            </InfoBox>
          )}
        </Marker>
      )}
      {filteredOrganisations &&
        filteredOrganisations
          .filter(
            (summary) =>
              summary.classifierIds !== null &&
              summary.classifierIds.length > 0 &&
              homeCtx.simpleIcons.get(getRelevantIconId(summary.classifierIds) ?? 0) !== undefined
          )
          .map((summary, index) => (
            <Marker
              key={`${summary.id}-${index}`}
              position={{ lat: summary.lat, lng: summary.lng }}
              zIndex={randomZIndexListRef.current[index]}
              icon={
                visibleMarkerCount <= MARKER_THRESHOLD
                  ? {
                      scaledSize: isStreetView ? new google.maps.Size(65, 75) : getPinSize(),
                      url: getIcon(summary.classifierIds) ?? ''
                    }
                  : {
                      url: homeCtx.simpleIcons.get(getRelevantIconId(summary.classifierIds) ?? 0) ?? ''
                    }
              }
              onClick={() => homeCtx.setSelectedOrganisation(summary)}
              options={{ map: map }}
            />
          ))}
      <HomeScreenOverlay
        map={map}
        satellite={satellite}
        setSatellite={setSatellite}
        displayCounter={{ num: filteredOrganisations.length, max: props.data?.length ?? 0 }}
      />
    </GoogleMap>
  );
};

export default OrganisationMap;
